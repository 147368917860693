body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  background-color: #333;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

html, body, #root, .App {
  width: 100%;
}

div#uploader {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
}

div#result {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;

  img {
    max-width: 100%;
    max-height: 750px;
  }
}

h1 {
  font-size: 2em;
  margin-bottom: 0;
}

p {
  margin-top: 0;
}

a {
  color: inherit;
}

.card-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  color: black;
}

.large {
  font-size: 1.5em;
  margin-bottom: 0;
}

.location {
  font-size: 1.2em;
  margin-bottom: 6px;
}

.hot-single {
  font-weight: normal;
  margin-top: 5px;
  margin-bottom: 10px;
}

.card {
  max-width: 500px;
  max-height: 100vh;
  position: relative;
  width: auto;
  display: inline-block;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 5px 5px 10px 1px rgba(255, 255, 255, 0.4);
}